import { NextPageContext } from 'next'
import styled from '@emotion/styled'
import { Layout } from '../components/layout/Layout'
import { ParallaxWrapper } from '../components/parallax/ParallaxWrapper'

const Styled = {
  Layout: styled.div({
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  }),
  Travolta: styled.div({
    background: 'url("/confused_travolta.svg")',
    width: 300,
    height: 300,
    margin: '0 auto',
  }),
}

const Error = ({ statusCode }: { statusCode: number }) => (
  <ParallaxWrapper>
    <Layout>
      <Styled.Layout>
        {statusCode
          ? `An error ${statusCode} occurred on server`
          : (
            <div>
              <h1>404</h1>
              <p>Oh, I’m confused. Are you sure this is the page you are looking for?</p>
              <Styled.Travolta />
            </div>
          )}
      </Styled.Layout>
    </Layout>
  </ParallaxWrapper>
)

export const getInitialProps = ({ res, err }: NextPageContext) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404
  return { statusCode }
}

export default Error
